<template>
    <div class="table-responsive-lg mt-4">
  <table class="table table-hover table-striped table-bordered">
    <thead class="table-dark">
      <tr>
        <th style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff;">ID</th>
        <th style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff;">Service Name</th>
        <th style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff;">Fuel Charge %</th>
        <th style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff;">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="service in services" :key="service.id">
        <td style="text-align: center;">{{ service.id }}</td>
        <td style="text-align: center;">{{ service.service_name }}</td>
        <td style="text-align: center;">
          <input
            type="number"
            step="0.01"
            v-model="service.fuel_charge"
            class="form-control"
          />
        </td>
        <td style="text-align: center;">
          <button
            class="btn btn-more"
            @click="updateFuelCharge(service)"
          >
            Update
          </button>
        </td>
      </tr>
      <tr v-if="!services.length">
        <td colspan="4" class="text-center">No services available</td>
      </tr>
    </tbody>
  </table>
</div>
</template>


<script>
import axios from "axios";

export default {
  name: "Dashboard",
  data() {
    return {
      services: [],
    };
  },
  methods: {
    async fetchServices() {
      try {
        const response = await axios.get("/api/v1/services/");
        this.services = response.data;
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    },
    async updateFuelCharge(service) {
      try {
        const response = await axios.put(`/api/v1/services/${service.id}/`, {
          fuel_charge: service.fuel_charge,
        });
        alert(`Fuel charge updated for ${response.data.service_name}`);
      } catch (error) {
        console.error("Error updating fuel charge:", error);
        alert("Failed to update fuel charge.");
      }
    },
  },
  async mounted() {
    await this.fetchServices();
  },
};
</script>
