<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark py-3 shadow" aria-label="Tenth navbar example">
    <div class="container-fluid">
      <a class="navbar-brand fw-bold text-uppercase" href="#">Eurekali Admin</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarExample"
        aria-controls="navbarExample" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse justify-content-center" id="navbarExample">
        <ul class="navbar-nav text-center">
          <li class="nav-item">
            <router-link to="/shipments-progress"
              :class="['nav-link', isActive('/shipments-progress') ? 'active' : '']">
              Accepted
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/shipments-pending" :class="['nav-link', isActive('/shipments-pending') ? 'active' : '']">
              Pending
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/shipments-delivered"
              :class="['nav-link', isActive('/shipments-delivered') ? 'active' : '']">
              Delivered
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/users" :class="['nav-link', isActive('/users') ? 'active' : '']">
              Users
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/service-points" :class="['nav-link', isActive('/service-points') ? 'active' : '']">
              Service Points
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/statistics" :class="['nav-link', isActive('/statistics') ? 'active' : '']">
              Statistics
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/invoices" :class="['nav-link', isActive('/invoices') ? 'active' : '']">
              Factures
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/refunds" :class="['nav-link', isActive('/refunds') ? 'active' : '']">
              Remboursements
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/reports" :class="['nav-link', isActive('/reports') ? 'active' : '']">
              Rapports
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/transporters" :class="['nav-link', isActive('/transporters') ? 'active' : '']">
              Transporters
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/clients" :class="['nav-link', isActive('/clients') ? 'active' : '']">
              Clients
            </router-link>
          </li>
          <li class="nav-item">
            <button class="btn btn-outline-danger ms-lg-3" @click="logout()">
              Log Out
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>




<script>
import axios from 'axios'
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';



export default {
  mixins: [en, fr, nl],
  data() {
    return {
      orders: [],
      lang: this.$store.state.lang,
    }
  },
  computed: {
    lang2() {
      return this.$store.state.lang
    },
  },
  mounted() {
    const language = navigator.language.split('-')[0];
    const supportedLanguages = ['en', 'fr', 'nl'];

    if (supportedLanguages.includes(language)) {
      this.lang = language;
    } else {
      this.lang = 'en';
    }

    this.updateLang()

  },
  methods: {
    translate(prop) {
      return this[this.lang2][prop];
    },
    updateLang() {
      this.$store.commit('setLang', this.lang);
    },
    isActive(route) {
      return this.$route.path === route;
    },
    logout() {
      axios.defaults.headers.common["Authorization"] = ""
      sessionStorage.removeItem("token")
      this.$store.commit('removeToken')
      this.$store.commit('clearShipment')
      this.$router.push('/login')
    }
  }
}
</script>


<style>
.nav-link {
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff !important;
  margin: 0 8px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  border-radius: 5px;
}


.nav-link:hover {
  background-color: #495057;
  color: #E5097F !important;
}

/* Active link styling */
.nav-link.active {
  background-color: #E5097F !important;
  color: #fff !important;
  border-radius: 5px;
}

/* Button styles */
.btn-outline-danger {
  font-size: 0.9rem;
  padding: 4px 10px;
  border-width: 2px;
  transition: all 0.3s ease-in-out;
}

.btn-outline-danger:hover {
  background-color: #dc3545;
  color: #ffffff;
}
</style>
